import {Check, Clear} from '@mui/icons-material'
import {
  Box,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {getKgjsForBps, updateKgjForBps, getBpsDetailsV2, updateBpsV2, updateGasForBps} from '../../../api/bpsApi'
import {
  BpsParametersExtended,
  Kgj,
  UpdateBpsParametersExtendedRequest,
  UpdateGasRequest,
  User,
} from '../../../api/generated'
import {useAsyncMethodWithErrorHandling} from '../../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../../utils/BucketStoreContext'
import {transformToKwWithEndKw} from '../../../utils/common'
import {EditKgjDialog} from '../../EditKgjDialog'
import {BpsTable} from './BPSTable'
import {EditBpsDialog} from '../../EditBpsDialog'
import {KgjTable} from './KgjTable'
import {ErrorUpdatingBpsDialog} from '../../../../src/components/ErrorUpdatingBpsDialog'
import {UpdateNominationsAfterTvsUpdateDialog} from '../../UpdateNominationsAfterTvsUpdateDialog'
import {UpdateNominationsAfterKgjUpdateDialog} from '../../UpdateNominationsAfterKgjUpdateDialog'
import {RecalculatingNominationsDialog} from '../../RecalculatingNominationsDialog'
import {GasTable} from './GasTable'
import {EditGasDialog} from '../../EditGasDialog'
import {UpdateKgjDosDialog} from '../../UpdateKgjDosDialog'

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledTableHeadRow = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const getDosMinMw = (kgjsForBps: Kgj[] | undefined) => kgjsForBps?.reduce((total, item) => total + item.p_dos_min_mw, 0)
const getDosMaxMw = (kgjsForBps: Kgj[] | undefined) => kgjsForBps?.reduce((total, item) => total + item.p_dos_max_mw, 0)
const getRegMinMw = (kgjsForBps: Kgj[] | undefined) => kgjsForBps?.reduce((total, item) => total + item.p_reg_min_mw, 0)
const getRegMaxMw = (kgjsForBps: Kgj[] | undefined) => kgjsForBps?.reduce((total, item) => total + item.p_reg_max_mw, 0)

export const BpsDetail: React.FC = () => {
  const [bpsMembers, setBpsMembers] = useState<User[] | undefined>([])
  const [bpsParameters, setBpsParameters] = useState<BpsParametersExtended>()
  const [kgjsForBps, setKgjsForBps] = useState<Kgj[] | undefined>([])
  const [kgjId, setKgjId] = useState<number | undefined>(undefined)

  const [isBpsUpdateErrorDialogOpen, setIsBpsUpdateErrorDialogOpen] = useState<boolean>(false)
  const [isEditKgjDialogOpen, setIsEditKgjDialogOpen] = useState<boolean>(false)
  const [isEditBpsDialogOpen, setIsEditBpsDialogOpen] = useState<boolean>(false)
  const [isEditGasDialogOpen, setIsEditGasDialogOpen] = useState<boolean>(false)
  const [isUpdateNominationsAfterTvsUpdateDialogOpen, setIsUpdateNominationsAfterTvsUpdateDialogOpen] =
    useState<boolean>(false)
  const [isUpdateKgjDosDialogOpen, setIsUpdateKgjDosDialogOpen] = useState<boolean>(false)
  const [isUpdateNominationsAfterKgjUpdateDialogOpen, setIsUpdateNominationsAfterKgjUpdateDialogOpen] =
    useState<boolean>(false)
  const [recalculatingNominationsDialogOpen, setRecalculatingNominationsDialogOpen] = useState<boolean>(false)

  const updateBpsRequest = useRef<UpdateBpsParametersExtendedRequest | undefined>(undefined)
  const updateKgjRequest = useRef<Kgj | undefined>(undefined)
  const originalKgjs = useRef<Kgj[] | undefined>(undefined)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const selectedIdOld = useRef<number | undefined>(undefined)
  const getKgjs = useRef<boolean>(false)

  const {run: runGetBpsDetailsV2} = useAsyncMethodWithErrorHandling(getBpsDetailsV2)
  const {run: runUpdateBpsV2} = useAsyncMethodWithErrorHandling(updateBpsV2)
  const {run: runUpdateKgj} = useAsyncMethodWithErrorHandling(updateKgjForBps)
  const {run: runUpdateGas} = useAsyncMethodWithErrorHandling(updateGasForBps)

  const {run: runGetKgjsForBps} = useAsyncMethodWithErrorHandling(getKgjsForBps)

  const fetchBpsDetails = React.useCallback(async () => {
    if (selectedBpsId && selectedIdOld.current !== selectedBpsId) {
      selectedIdOld.current = selectedBpsId
      getKgjs.current = true
      const result = (await runGetBpsDetailsV2({id: selectedBpsId})).data

      setBpsMembers(result?.users)
      setBpsParameters(result?.parameters)
    }
  }, [runGetBpsDetailsV2, selectedBpsId])

  const fetchKgjsForBps = React.useCallback(async () => {
    if (getKgjs.current && selectedBpsId) {
      getKgjs.current = false
      const result = (await runGetKgjsForBps({id: selectedBpsId})).data
      setKgjsForBps(result?.kgjs)
      originalKgjs.current = JSON.parse(JSON.stringify(result?.kgjs))
    }
  }, [runGetKgjsForBps, selectedBpsId])

  useEffect(() => {
    fetchBpsDetails()
  }, [fetchBpsDetails])

  useEffect(() => {
    fetchKgjsForBps()
  }, [fetchKgjsForBps])

  const handleCloseEditKgjDialog = () => {
    setIsEditKgjDialogOpen(false)
  }

  const handleCloseEditBpsDialog = () => {
    setIsEditBpsDialogOpen(false)
  }

  const handleCloseEditGasDialog = () => {
    setIsEditGasDialogOpen(false)
  }

  const updateKgjParams = async (request: Kgj) => {
    if (!selectedBpsId || !bpsParameters?.id) {
      return
    }

    setIsUpdateNominationsAfterKgjUpdateDialogOpen(false)
    handleCloseEditKgjDialog()

    await runUpdateKgj({
      id: selectedBpsId,
      updateKgjRequest: request,
    })

    setRecalculatingNominationsDialogOpen(false)

    selectedIdOld.current = -1
    fetchBpsDetails()
    fetchKgjsForBps()
  }

  const handleUpdateReg = async (updatedKgj: Kgj) => {
    if (!selectedBpsId) {
      return
    }

    const originalKgj = originalKgjs.current?.find((item) => item.id === updatedKgj.id)

    if (
      originalKgj?.p_reg_min_mw !== updatedKgj.p_reg_min_mw ||
      originalKgj?.p_reg_max_mw !== updatedKgj.p_reg_max_mw
    ) {
      updateKgjRequest.current = updatedKgj
      setIsUpdateNominationsAfterKgjUpdateDialogOpen(true)
      return
    }

    await updateKgjParams(updatedKgj)
  }

  const handleUpdateKgjDos = (updatedKgj: Kgj) => {
    if (!selectedBpsId) {
      return
    }

    const originalKgj = originalKgjs.current?.find((item) => item.id === updatedKgj.id)

    if (
      originalKgj?.p_dos_min_mw !== updatedKgj.p_dos_min_mw ||
      originalKgj?.p_dos_max_mw !== updatedKgj.p_dos_max_mw
    ) {
      updateKgjRequest.current = updatedKgj
      setIsUpdateKgjDosDialogOpen(true)
      return
    }

    handleUpdateReg(updatedKgj)
  }

  const updateBpsParams = async (request: UpdateBpsParametersExtendedRequest) => {
    if (!selectedBpsId || !bpsParameters?.id) {
      return
    }

    const result = (await runUpdateBpsV2({id: bpsParameters?.id, updateBpsParametersExtendedRequest: request})).data
    setBpsMembers(result?.users)
    setBpsParameters(result?.parameters)

    updateBpsRequest.current = undefined

    handleCloseEditBpsDialog()
  }

  const handleConfirmBpsUpdate = async (request: UpdateBpsParametersExtendedRequest) => {
    if (request.tvs_mw !== bpsParameters?.tvs_mw) {
      updateBpsRequest.current = request
      setIsUpdateNominationsAfterTvsUpdateDialogOpen(true)
    } else {
      updateBpsParams(request)
    }
  }

  const updateGas = async (request: UpdateGasRequest) => {
    if (!selectedBpsId || !bpsParameters?.id) {
      return
    }

    const result = (await runUpdateGas({id: bpsParameters?.id, updateGasRequest: request})).data
    setBpsParameters(result?.parameters)

    handleCloseEditGasDialog()
  }

  const handleConfirmGasUpdate = async (request: UpdateGasRequest) => {
    updateGas(request)
  }

  const handleBpsUpdateError = () => {
    setIsBpsUpdateErrorDialogOpen(true)
  }

  const handleBpsUpdateErrorClose = () => {
    setIsBpsUpdateErrorDialogOpen(false)
  }

  const handleUpdateNominationsAfterTvsUpdateDialogConfirm = () => {
    setIsUpdateNominationsAfterTvsUpdateDialogOpen(false)
    updateBpsRequest.current && updateBpsParams(updateBpsRequest.current)
  }

  const handleUpdateNominationsAfterTvsUpdateDialogClose = () => {
    setIsUpdateNominationsAfterTvsUpdateDialogOpen(false)
  }

  const handleUpdateNominationsAfterKgjUpdateDialogClose = async () => {
    setIsUpdateNominationsAfterKgjUpdateDialogOpen(false)
  }

  const handleUpdateDosDialogConfirm = () => {
    setIsUpdateKgjDosDialogOpen(false)

    if (updateKgjRequest.current) {
      handleUpdateReg(updateKgjRequest.current)
    }
  }

  const handleUpdateDosDialogClose = () => {
    setIsUpdateKgjDosDialogOpen(false)
  }

  const handleUpdateNominationsAfterKgjUpdateDialogConfirm = async () => {
    if (!selectedBpsId || updateKgjRequest.current === undefined) {
      return
    }

    setRecalculatingNominationsDialogOpen(true)
    await updateKgjParams(updateKgjRequest.current)
  }

  return (
    <>
      <Stack style={{marginTop: '10px'}}>
        {bpsParameters && (
          <BpsTable
            bpsParams={bpsParameters}
            onEditBps={() => {
              setIsEditBpsDialogOpen(true)
            }}
            // in MW
            minVykonDos={transformToKwWithEndKw(getDosMinMw(kgjsForBps))}
            maxVykonDos={transformToKwWithEndKw(getDosMaxMw(kgjsForBps))}
            minVykonReg={transformToKwWithEndKw(getRegMinMw(kgjsForBps))}
            maxVykonReg={transformToKwWithEndKw(getRegMaxMw(kgjsForBps))}
          />
        )}
      </Stack>
      <Stack style={{marginTop: '30px'}}>
        {bpsParameters && (
          <GasTable
            bpsParams={bpsParameters}
            onEditGas={() => {
              setIsEditGasDialogOpen(true)
            }}
          />
        )}
      </Stack>
      <Stack style={{marginTop: '30px'}}>
        {kgjsForBps && (
          <KgjTable
            kgjs={kgjsForBps}
            onEdit={(id) => {
              setKgjId(id)
              setIsEditKgjDialogOpen(true)
            }}
          />
        )}
      </Stack>

      <Typography marginTop={3} sx={{fontSize: '20px', fontWeight: '500'}}>
        Primárny kontakt
      </Typography>
      <Stack direction="column" justifyContent="flex-start" marginTop={1} sx={{gap: '5px'}}>
        <Box>
          <Typography>Manažér: {bpsParameters?.manager?.name ?? 'neuvedený'}</Typography>
        </Box>
        <Box>
          <Typography>Operátor: {bpsParameters?.operator?.name ?? 'neuvedený'}</Typography>
        </Box>
      </Stack>

      <Typography marginTop={5} sx={{fontSize: '20px', fontWeight: '500'}}>
        Členovia
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" sx={{padding: '20px 0'}}>
          <TableHead>
            <StyledTableHeadRow>
              <TableCell>Meno</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Telefónne číslo</TableCell>
              <TableCell>Emailové notifikácie</TableCell>
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {bpsMembers?.map((item) => (
              <StyledTableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone_number}</TableCell>
                <TableCell>
                  {item.notify_by_email ? (
                    <Check fontSize="small" color="success" />
                  ) : (
                    <Clear fontSize="small" color="error" />
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditBpsDialogOpen && bpsParameters && (
        <EditBpsDialog
          open={isEditBpsDialogOpen}
          onClose={handleCloseEditBpsDialog}
          onConfirm={handleConfirmBpsUpdate}
          bpsParamsExtended={bpsParameters}
          onErrorDefaultMw={handleBpsUpdateError}
        />
      )}

      {isEditGasDialogOpen && bpsParameters && (
        <EditGasDialog
          open={isEditGasDialogOpen}
          onClose={handleCloseEditGasDialog}
          onConfirm={handleConfirmGasUpdate}
          bpsParamsExtended={bpsParameters}
        />
      )}

      {isEditKgjDialogOpen && kgjsForBps && (
        <EditKgjDialog
          open={isEditKgjDialogOpen}
          onClose={handleCloseEditKgjDialog}
          onConfirm={handleUpdateKgjDos}
          kgj={kgjsForBps.find((item) => item.id === kgjId)}
        />
      )}

      {isBpsUpdateErrorDialogOpen && (
        <ErrorUpdatingBpsDialog
          open={isBpsUpdateErrorDialogOpen}
          onClose={handleBpsUpdateErrorClose}
          pInstMwValue={transformToKwWithEndKw(bpsParameters?.p_inst_mw)}
        />
      )}

      {isUpdateNominationsAfterTvsUpdateDialogOpen && (
        <UpdateNominationsAfterTvsUpdateDialog
          open={isUpdateNominationsAfterTvsUpdateDialogOpen}
          onClose={handleUpdateNominationsAfterTvsUpdateDialogClose}
          onConfirm={handleUpdateNominationsAfterTvsUpdateDialogConfirm}
        />
      )}

      {isUpdateNominationsAfterKgjUpdateDialogOpen && (
        <UpdateNominationsAfterKgjUpdateDialog
          open={isUpdateNominationsAfterKgjUpdateDialogOpen}
          onClose={handleUpdateNominationsAfterKgjUpdateDialogClose}
          onConfirm={handleUpdateNominationsAfterKgjUpdateDialogConfirm}
        />
      )}

      {isUpdateKgjDosDialogOpen && (
        <UpdateKgjDosDialog
          open={isUpdateKgjDosDialogOpen}
          onClose={handleUpdateDosDialogClose}
          onConfirm={handleUpdateDosDialogConfirm}
        />
      )}

      {recalculatingNominationsDialogOpen && (
        <RecalculatingNominationsDialog open={recalculatingNominationsDialogOpen} />
      )}
    </>
  )
}
