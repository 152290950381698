import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'

type UpdateNominationsAfterTvsUpdateDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const UpdateNominationsAfterKgjUpdateDialog: React.FC<UpdateNominationsAfterTvsUpdateDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Úprava regulačného rozsahu</DialogTitle>
      <DialogContent>
        <Typography color={'#ff0000'}>Upozornenie:</Typography>
        <Typography color={'#ff0000'}>
          Aktualizovali ste hodnoty regulačného rozsahu. Prosím, skontrolujte, či Vami zadané nominácie neprekračujú
          regulačný rozsah.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={() => onConfirm()} variant="contained">
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
