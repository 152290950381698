import React, {useCallback, useEffect} from 'react'
import {validateNumericValueBelow100, validateNumericValueBelow100AndInRange} from '../utils/common'
import {NominationExtendedBase} from '../types/nominationExtended'
import {Box, Slider, TextField} from '@mui/material'
import {NominationProfile} from '../api/generated'

type NominationDialogSliderProps = {
  editedNomination: NominationExtendedBase | NominationProfile
  minValueForSlider: number
  maxValueForSlider: number
  onEditProperties: (changes: Partial<NominationExtendedBase>) => void
  onValueChanged: (valid: boolean) => void
}

export const NominationDialogSlider: React.FC<NominationDialogSliderProps> = ({
  editedNomination,
  onEditProperties,
  minValueForSlider,
  maxValueForSlider,
  onValueChanged,
}) => {
  const maxValidToUse = maxValueForSlider < minValueForSlider ? minValueForSlider : maxValueForSlider

  const valueIsValid = useCallback(() => {
    return (
      (editedNomination.value_percentage ?? 0) >= minValueForSlider &&
      (editedNomination.value_percentage ?? 0) <= maxValidToUse
    )
  }, [editedNomination.value_percentage, maxValidToUse, minValueForSlider])

  useEffect(() => {
    onValueChanged(valueIsValid())
  }, [editedNomination.value_percentage, onValueChanged, valueIsValid])

  const getHelperText = !valueIsValid()
    ? 'Hodnota nesmie byť prázdna a musí byť medzi ' + minValueForSlider + ' a ' + maxValidToUse.toFixed(0) + '%'
    : ''

  return (
    <>
      <TextField
        margin="normal"
        label="Nominácia BPS (%)"
        type="text"
        value={editedNomination.value_percentage ?? 0}
        onChange={(e) => {
          if (validateNumericValueBelow100(e.target.value)) {
            onEditProperties({value_percentage: +e.target.value})
          }
          onValueChanged(valueIsValid())
        }}
        error={!valueIsValid()}
        helperText={getHelperText}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Box>
        <Slider
          value={editedNomination.value_percentage ?? 0}
          step={1}
          min={0}
          max={100}
          onChange={(event, value) => {
            if (validateNumericValueBelow100AndInRange(value.toString(), minValueForSlider, maxValidToUse)) {
              onEditProperties({value_percentage: +value})
            }
            onValueChanged(valueIsValid())
          }}
          color={'secondary'}
          marks={[
            {
              value: 0,
              label: '0%',
            },
            {
              value: 25,
              label: '25%',
            },
            {
              value: 50,
              label: '50%',
            },
            {
              value: 75,
              label: '75%',
            },
            {
              value: 100,
              label: '100%',
            },
          ]}
        />
      </Box>
    </>
  )
}
