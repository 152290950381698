import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {Kgj, NominationKgj, NominationProfile} from '../api/generated'
import {useBucketStore} from '../utils/BucketStoreContext'
import {formatTimeRange} from '../utils/format'
import {checkOverlapWithKgjs, mergeNominationsWithKgjs} from './profile-nominations-merge-utils'
import {transformToKwWithEndKwWithoutDecimals} from '../utils/common'
import {NominationProfileWithKgjs} from '../api/generated/model/nomination-profile-with-kgjs'

type ConfirmUpdateProfileDialogProps = {
  open: boolean
  onClose: () => void
  profileNomination: NominationProfile
  onConfirm: () => void
  pInstMw: number | undefined
  tvsMw: number | undefined
  nominationKgjs: NominationKgj[]
  allKgjs: Kgj[] | undefined
}

export const ConfirmUpdateProfileDialog: React.FC<ConfirmUpdateProfileDialogProps> = ({
  open,
  profileNomination,
  onClose,
  onConfirm,
  pInstMw,
  tvsMw,
}) => {
  const {data: profileNominationsWithKgjs} = useBucketStore('profileNominationsWithKgjs')
  const {data: selectedProfile} = useBucketStore('selectedProfile')

  const existingRecords = useMemo(() => {
    const overlappingRecords = profileNominationsWithKgjs.filter((x) => checkOverlapWithKgjs(x, profileNomination))
    return mergeNominationsWithKgjs(overlappingRecords)
  }, [profileNominationsWithKgjs, profileNomination])

  const formatTimeToHourMinutes = (time: string) => {
    return time.substring(0, time.lastIndexOf(':'))
  }

  const bpsNomination = (valuePercentage: number) => {
    const firstPart = (pInstMw ?? 0) * (valuePercentage / 100)
    return firstPart - (tvsMw ?? 0)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Upraviť profil: {selectedProfile?.name}</DialogTitle>
      <DialogContent>
        {existingRecords.length > 0 && (
          <Box marginBottom={2}>
            <Typography fontWeight={500}>Existujúci profil nominácií:</Typography>
            {existingRecords.map((nomination: NominationProfileWithKgjs, i) => (
              <Box key={i} marginTop="4px">
                <Typography>
                  {formatTimeToHourMinutes(nomination.time_from) + '  - ' + formatTimeToHourMinutes(nomination.time_to)}
                  , {nomination.value_percentage}%{' '}
                  {transformToKwWithEndKwWithoutDecimals(bpsNomination(nomination.value_percentage ?? 0))}
                </Typography>
                {nomination.note && <Typography variant="body2">Poznámka: {nomination.note}</Typography>}
              </Box>
            ))}
          </Box>
        )}

        <Typography fontWeight={500}>Nový profil:</Typography>
        <Typography>
          {formatTimeRange(profileNomination.time_from, profileNomination.time_to)},{' '}
          {profileNomination.value_percentage}%{' '}
          {transformToKwWithEndKwWithoutDecimals(bpsNomination(profileNomination.value_percentage ?? 0))}
        </Typography>
        {profileNomination.note && <Typography variant="body2">Poznámka: {profileNomination.note}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={onConfirm} variant="contained">
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
