import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'
import {transformToKwNumber, transformToMwNumber} from '../utils/common'
import {Kgj} from '../api/generated'

type EditKgjDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: (updatedKgj: Kgj) => void
  kgj: Kgj | undefined
}

export const EditKgjDialog: React.FC<EditKgjDialogProps> = ({open, onClose, onConfirm, kgj}) => {
  const [newName, setNewName] = useState<string>(kgj?.name ?? '')
  const [newDosMinMw, setNewDosMinMw] = useState<number | undefined>(transformToKwNumber(kgj?.p_dos_min_mw))
  const [newDosMaxMw, setNewDosMaxMw] = useState<number | undefined>(transformToKwNumber(kgj?.p_dos_max_mw))
  const [newRegMinMw, setNewRegMinMw] = useState<number | undefined>(transformToKwNumber(kgj?.p_reg_min_mw))
  const [newRegMaxMw, setNewRegMaxMw] = useState<number | undefined>(transformToKwNumber(kgj?.p_reg_max_mw))
  const [newInService, setNewInService] = useState<boolean | undefined>(kgj?.in_service)

  const [errorValues, setErrorValues] = useState<boolean>(false)

  const isValidValue = (value: string | undefined) => {
    const instMw = transformToKwNumber(kgj?.p_inst_mw) ?? 0
    return Number(value) <= instMw
  }

  // “regulačný” interval musí byť v intervale alebo sa rovnať “dosiahnuteľnému” intervalu.
  const regValuesInInterval = () => {
    return (
      newDosMinMw &&
      newDosMaxMw &&
      newRegMinMw &&
      newRegMaxMw &&
      newRegMinMw >= newDosMinMw &&
      newRegMinMw <= newDosMaxMw &&
      newRegMaxMw >= newDosMinMw &&
      newRegMaxMw <= newDosMaxMw
    )
  }

  const allValuesCorrect = () => {
    const pInstKw = transformToKwNumber(kgj?.p_inst_mw) ?? 0
    return (
      // are defined
      newDosMinMw &&
      newDosMaxMw &&
      newRegMinMw &&
      newRegMaxMw &&
      // are less then pInstKw
      newDosMinMw <= newDosMaxMw &&
      newDosMinMw <= pInstKw &&
      newDosMaxMw <= pInstKw &&
      newRegMinMw <= newRegMaxMw
    )
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Upraviť KGJ</DialogTitle>
        <DialogContent>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>Názov KGJ:</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="názov kjg"
                    type="text"
                    value={newName ?? ''}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setNewName(e.target.value)
                      }
                    }}
                    error={newName.length === 0}
                    helperText={newName.length !== 0 ? '' : 'Hodnota nesmie byť prázdna'}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography>Výkon inštalovaný [kW]:</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="kW"
                    type="text"
                    value={transformToKwNumber(kgj?.p_inst_mw) ?? ''}
                    disabled={true}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography>Min. výkon dosiahnuteľný [kW]:</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="kW"
                    type="text"
                    value={newDosMinMw ?? ''}
                    onChange={(e) => {
                      if (isValidValue(e.target.value)) {
                        setNewDosMinMw(Number(e.target.value))
                      }
                    }}
                    error={!isValidValue(newDosMinMw?.toString())}
                    helperText={isValidValue(newDosMinMw?.toString()) ? '' : 'Hodnota nesmie byť prázdna'}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography>Max. výkon dosiahnuteľný [kW]:</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="kW"
                    type="text"
                    value={newDosMaxMw ?? ''}
                    onChange={(e) => {
                      if (isValidValue(e.target.value)) {
                        setNewDosMaxMw(Number(e.target.value))
                      }
                    }}
                    error={!isValidValue(newDosMaxMw?.toString())}
                    helperText={isValidValue(newDosMaxMw?.toString()) ? '' : 'Hodnota nesmie byť prázdna'}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography>Min. výkon regulačný [kW]:</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="kW"
                    type="text"
                    value={newRegMinMw ?? ''}
                    onChange={(e) => {
                      if (isValidValue(e.target.value)) {
                        setNewRegMinMw(Number(e.target.value))
                      }
                    }}
                    error={!isValidValue(newRegMinMw?.toString())}
                    helperText={isValidValue(newRegMinMw?.toString()) ? '' : 'Hodnota nesmie byť prázdna'}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography>Max. výkon regulačný [kW]:</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="kW"
                    type="text"
                    value={newRegMaxMw ?? ''}
                    onChange={(e) => {
                      if (isValidValue(e.target.value)) {
                        setNewRegMaxMw(Number(e.target.value))
                      }
                    }}
                    error={!isValidValue(newRegMaxMw?.toString())}
                    helperText={isValidValue(newRegMaxMw?.toString()) ? '' : 'Hodnota nesmie byť prázdna'}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography>V prevádzke</Typography>
                </TableCell>
                <TableCell>
                  <Checkbox checked={newInService} onChange={(checkBox) => setNewInService(checkBox.target.checked)} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button
            onClick={() => {
              if (!regValuesInInterval()) {
                setErrorValues(true)
              } else if (allValuesCorrect()) {
                const updatedKgj = kgj
                if (updatedKgj) {
                  updatedKgj.name = newName
                  updatedKgj.p_dos_min_mw = transformToMwNumber(newDosMinMw)
                  updatedKgj.p_dos_max_mw = transformToMwNumber(newDosMaxMw)
                  updatedKgj.p_reg_min_mw = transformToMwNumber(newRegMinMw)
                  updatedKgj.p_reg_max_mw = transformToMwNumber(newRegMaxMw)
                  updatedKgj.in_service = newInService === true

                  onConfirm(updatedKgj)
                }
              }
            }}
            variant="contained"
            disabled={!allValuesCorrect()}
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
      {errorValues && (
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Upraviť KGJ</DialogTitle>
          <DialogContent>
            <Typography color={'#ff0000'}>
              Upozornenie:
              <br />
              <br />
              Hodnoty regulačného rozsahu musia byť v intervale hodnôt dosiahnuteľného rozsahu
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorValues(false)} variant="contained">
              Zavrieť
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
