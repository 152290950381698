import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'

type UpdateKgjDosDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const UpdateKgjDosDialog: React.FC<UpdateKgjDosDialogProps> = ({open, onClose, onConfirm}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Úprava dosiahnuteľného rozsahu</DialogTitle>
      <DialogContent>
        <Typography color={'#ff0000'}>Upozornenie::</Typography>
        <Typography color={'#ff0000'}>
          Aktualizovali ste hodnoty dosiahnuteľného rozsahu. Prosím, skontrolujte, či Vami zadané nominácie neprekračujú
          dosiahnuteľný rozsah.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={() => onConfirm()} variant="contained">
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
