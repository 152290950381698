import {
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Create} from '@mui/icons-material'
import React, {useState, useEffect} from 'react'
import {BpsCounterImbalance} from '../../api/generated'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {StyledIconButton} from './GroupNominationsTable'
import {EditCounterImbalanceDialog} from '../EditCounterImbalanceDialog'
import {BpsRemoteControlDialog} from '../BpsRemoteControlDialog'
import {getCounterImbalanceForBps} from '../../api/bpsApi'
import {transformToKwNumber} from '../../utils/common'

const StyledTableHeadRowForCounterImbalanceTable = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

export const OperationView: React.FC = () => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: userInfo} = useBucketStore('userInfo')

  const [openTableRow, setOpenTableRow] = useState<number | undefined>(1)
  const [checkBoxEnableCI, setCheckBoxEnableCI] = React.useState(true)

  const [isEditCounterImbalanceDialogOpen, setIsEditCounterImbalanceDialogOpen] = useState<boolean>(false)
  const [isBpsRemoteControlDialogOpen, setIsBpsRemoteControlDialogOpen] = useState<boolean>(false)

  const [bpsCounterImbalance, setBpsCounterImbalance] = useState<BpsCounterImbalance>()

  const {run: runGetCounterImbalanceForBps} = useAsyncMethodWithErrorHandling(getCounterImbalanceForBps)

  const handleOpenCounterImbalance = (index: number) => {
    setOpenTableRow(index)
  }

  const handleCloseCounterImbalanceTable = () => {
    setOpenTableRow(-1)
  }

  const handleCloseEditCounterImbalanceDialog = () => {
    setIsEditCounterImbalanceDialogOpen(false)
  }

  const handleCloseBpsRemoteControlDialog = () => {
    setIsBpsRemoteControlDialogOpen(false)
  }

  const fetchBpsCounterImbalance = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetCounterImbalanceForBps({bpsId: selectedBpsId})).data

      setBpsCounterImbalance(result)
      setCheckBoxEnableCI(
        result?.counter_imbalance_enabled == undefined ? checkBoxEnableCI : result.counter_imbalance_enabled,
      )
    }
  }, [runGetCounterImbalanceForBps, selectedBpsId, checkBoxEnableCI])

  const handleCounterImbalanceUpdate = () => {
    setIsEditCounterImbalanceDialogOpen(false)
    fetchBpsCounterImbalance()
  }

  useEffect(() => {
    fetchBpsCounterImbalance()
  }, [fetchBpsCounterImbalance])

  return (
    <>
      <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{gap: '12px', display: 'flex'}}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableHead>
              <StyledTableHeadRowForCounterImbalanceTable>
                <TableCell>Proti-odchýlkovanie</TableCell>
                <TableCell />
                <TableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      openTableRow == 1 ? handleCloseCounterImbalanceTable() : handleOpenCounterImbalance(1)
                    }
                  >
                    {openTableRow == 1 ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="small" />
                    )}
                  </IconButton>
                </TableCell>
              </StyledTableHeadRowForCounterImbalanceTable>
            </TableHead>

            <TableBody>
              <>
                <TableRow>
                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={openTableRow == 1} timeout="auto" unmountOnExit>
                      <TableContainer>
                        <Table size="small" sx={{padding: '20px 0'}}>
                          <TableHead>
                            <TableCell>Parametre</TableCell>
                            <TableCell>Hodnota</TableCell>
                            <TableCell style={{borderBottom: 'none'}}>
                              <StyledIconButton
                                sx={{marginLeft: '5px'}}
                                color="primary"
                                onClick={() => setIsEditCounterImbalanceDialogOpen(true)}
                              >
                                <Create fontSize="small" />
                              </StyledIconButton>
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            <>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none', verticalAlign: 'middle'}}>
                                  Povoliť proti-odchýlkovanie
                                </TableCell>
                                <TableCell style={{borderBottom: 'none', display: 'flex'}}>
                                  <Checkbox style={{padding: 0}} checked={checkBoxEnableCI} disabled />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none'}}>
                                  Regulačný rozsah nahor od Nominácie
                                </TableCell>
                                <TableCell style={{borderBottom: 'none'}}>
                                  {bpsCounterImbalance?.range_ci_max_mw == undefined
                                    ? '-'
                                    : transformToKwNumber(bpsCounterImbalance.range_ci_max_mw)}{' '}
                                  kW
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{borderBottom: 'none'}}>
                                  Regulačný rozsah nadol od Nominácie
                                </TableCell>
                                <TableCell style={{borderBottom: 'none'}}>
                                  {bpsCounterImbalance?.range_ci_min_mw == undefined
                                    ? '-'
                                    : transformToKwNumber(bpsCounterImbalance.range_ci_min_mw)}{' '}
                                  kW
                                </TableCell>
                              </TableRow>
                            </>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {(userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR')) && (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          marginTop={4}
          marginBottom={4}
          spacing={1}
          sx={{gap: '12px', display: 'flex'}}
        >
          <Button onClick={() => setIsBpsRemoteControlDialogOpen(true)}>Administrátor</Button>
        </Stack>
      )}

      {isEditCounterImbalanceDialogOpen && (
        <EditCounterImbalanceDialog
          open={isEditCounterImbalanceDialogOpen}
          onClose={handleCloseEditCounterImbalanceDialog}
          onUpdate={handleCounterImbalanceUpdate}
          counterImbalance={bpsCounterImbalance}
        />
      )}

      {isBpsRemoteControlDialogOpen && (
        <BpsRemoteControlDialog open={isBpsRemoteControlDialogOpen} onClose={handleCloseBpsRemoteControlDialog} />
      )}
    </>
  )
}
