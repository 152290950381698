import React, {useEffect} from 'react'
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from '@mui/material'
import {useBucketStore} from '../utils/BucketStoreContext'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {getRemoteControlForBps, updateRemoteControlForBps} from '../api/bpsApi'

type BpsRemoteControlDialogProps = {
  open: boolean
  onClose: () => void
}

export const BpsRemoteControlDialog: React.FC<BpsRemoteControlDialogProps> = ({open, onClose}) => {
  const [checkBoxThreshold, setCheckBoxThreshold] = React.useState(false)
  const [checkBoxRemoteControl, setCheckBoxRemoteControl] = React.useState(false)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const {run: runGetRemoteControlForBps} = useAsyncMethodWithErrorHandling(getRemoteControlForBps)
  const {run: runUpdateRemoteControlForBps} = useAsyncMethodWithErrorHandling(updateRemoteControlForBps)

  const handleConfirm = async () => {
    if (selectedBpsId) {
      await runUpdateRemoteControlForBps({
        updateBpsRemoteControlRequest: {
          bps_id: selectedBpsId,
          control_at_threshold: checkBoxThreshold,
          rc_enabled: checkBoxRemoteControl,
        },
      })
    }
    onClose()
  }

  const fetchBpsRemoteControl = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetRemoteControlForBps({bpsId: selectedBpsId})).data

      setCheckBoxThreshold((c) => (result?.control_at_threshold == undefined ? c : result.control_at_threshold))
      setCheckBoxRemoteControl((c) => (result?.rc_enabled == undefined ? c : result.rc_enabled))
    }
  }, [runGetRemoteControlForBps, selectedBpsId])

  useEffect(() => {
    fetchBpsRemoteControl()
  }, [fetchBpsRemoteControl])

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Administrátor</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography>Riadená na prahu</Typography>
            <Checkbox
              checked={checkBoxThreshold}
              onChange={(_, checked) => {
                setCheckBoxThreshold(checked)
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography>Povoliť vzdialené ovládanie</Typography>
            <Checkbox
              checked={checkBoxRemoteControl}
              onChange={(_, checked) => {
                setCheckBoxRemoteControl(checked)
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={handleConfirm} variant="contained">
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
