import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  styled,
} from '@mui/material'
import {PickersDay, StaticDatePicker} from '@mui/x-date-pickers'
import dayjs, {Dayjs} from 'dayjs'
import React, {useState} from 'react'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {sendGroupNominationsV3, sendNominationsToAggregator} from '../api/nominationsApi'
import {SendGroupNominationsExtendedResponse} from '../api/generated/model/send-group-nominations-extended-response'
import {SendNominationsToAggregatorExtendedResponse} from '../api/generated/model/send-nominations-to-aggregator-extended-response'

const StyledStaticDatePicker = styled(StaticDatePicker<string, Dayjs>)(() => ({
  '.MuiDayPicker-slideTransition': {
    minHeight: '200px',
  },
}))

type CalendarForGroupNominationsDialogProps = {
  open: boolean
  onCloseSendProductionPlan: () => void
  onFinishSendGroupNominations: (statusMessage: string) => void
  onFinishSendProductionPlanToAggregator: (statusMessage: string) => void
}

const SEND = {
  GROUP_NOMINATIONS: 'sendNominations',
  PLAN_TO_AGGREGATOR: 'sendPlanToAggregator',
}

const formatDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-')
  return `${day}.${month}.${year}`
}

const getTodayDateInArray = [dayjs().format('YYYY-MM-DD')]
const getTomorrowDateInArray = [dayjs().add(1, 'day').format('YYYY-MM-DD')]

export const CalendarForGroupNominationsDialog: React.FC<CalendarForGroupNominationsDialogProps> = ({
  open,
  onCloseSendProductionPlan,
  onFinishSendGroupNominations,
  onFinishSendProductionPlanToAggregator,
}) => {
  const [dates, setDates] = useState<string[]>([])
  const {run: runSendGroupNominationsV3} = useAsyncMethodWithErrorHandling(sendGroupNominationsV3)
  const {run: runSendNominationsToAggregator} = useAsyncMethodWithErrorHandling(sendNominationsToAggregator)

  const [sendTo, setSendTo] = React.useState<string>(SEND.GROUP_NOMINATIONS)

  const getErrorMessageFromResponse = (response?: SendGroupNominationsExtendedResponse) => {
    if (!response) {
      return 'Chybná odpoveď zo servera'
    }

    const notSentLength = response.not_sent_for_dates?.length ?? 0

    if (notSentLength > 0) {
      return notSentLength > 1
        ? 'Nepodarilo sa odoslať nominácie:' + response.not_sent_for_dates?.map(formatDate).join(', ')
        : 'Nepodarilo sa odoslať nomináciu: ' + response.not_sent_for_dates?.map(formatDate)
    }

    return ''
  }

  const getErrorMessageFromSendToAggregatorResponse = (response?: SendNominationsToAggregatorExtendedResponse) => {
    if (!response) {
      return 'Chybná odpoveď zo servera'
    }

    const notSentLength = response.not_sent_for_dates?.length ?? 0

    if (notSentLength > 0) {
      return notSentLength > 1
        ? 'Nepodarilo sa odoslať plán Agregátorovi pre dni: ' + response.not_sent_for_dates?.map(formatDate).join(', ')
        : 'Nepodarilo sa odoslať plán Agregátorovi pre den: ' + response.not_sent_for_dates?.map(formatDate)
    }

    return ''
  }

  const handleConfirm = async () => {
    onCloseSendProductionPlan()

    switch (sendTo) {
      case SEND.GROUP_NOMINATIONS:
        if (dates) {
          const response = (await runSendGroupNominationsV3({sendGroupNominationsRequest: {nominations_dates: dates}}))
            .data
          onFinishSendGroupNominations(getErrorMessageFromResponse(response))
        }
        break
      case SEND.PLAN_TO_AGGREGATOR:
        if (dates) {
          const response = (
            await runSendNominationsToAggregator({sendNominationsToAggregatorRequest: {dates_to_send: dates}})
          ).data
          onFinishSendProductionPlanToAggregator(getErrorMessageFromSendToAggregatorResponse(response))
        }
        break
    }
  }

  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSendTo(e.target.value)
  }

  return (
    <Dialog open={open} onClose={onCloseSendProductionPlan}>
      <DialogTitle>POSLAŤ PLÁN VÝROBY</DialogTitle>
      <DialogContent dividers>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-evenly">
          <FormControl>
            <RadioGroup
              defaultValue={SEND.GROUP_NOMINATIONS}
              name="radio-buttons-group-sending"
              onChange={handleRadioButtonChange}
            >
              <FormControlLabel
                value={SEND.GROUP_NOMINATIONS}
                control={<Radio />}
                label="POSLAŤ NOMINÁCIE ODBERATEĽOVI"
              />
              <FormControlLabel
                value={SEND.PLAN_TO_AGGREGATOR}
                control={<Radio />}
                label="POSLAŤ PLÁN VÝROBY AGREGÁTOROVI"
              />
            </RadioGroup>
          </FormControl>
        </Stack>

        <Stack width="95%" marginBottom={2} marginTop={1}>
          <div style={{height: '1px', backgroundColor: '#E0E0E0'}} />
        </Stack>

        <Stack direction="row" alignItems="flex-start" justifyContent="space-evenly">
          <Button onClick={() => setDates(getTodayDateInArray)} variant="outlined">
            Dnes
          </Button>
          <Button onClick={() => setDates(getTomorrowDateInArray)} variant="outlined">
            Zajtra
          </Button>
        </Stack>

        <Stack width="95%" marginTop={2}>
          <div style={{height: '1px', backgroundColor: '#E0E0E0'}} />
        </Stack>

        <Stack direction="column" alignItems="flex-start" justifyContent="center">
          <StyledStaticDatePicker
            onChange={(newValue) => {
              if (newValue) {
                if (dates.includes(dayjs(newValue).format('YYYY-MM-DD'))) {
                  setDates([...dates.filter((date) => date !== dayjs(newValue).format('YYYY-MM-DD')).sort()])
                } else {
                  setDates([...dates, dayjs(newValue).format('YYYY-MM-DD')].sort())
                }
              }
            }}
            value={null}
            renderInput={(params) => <TextField size="medium" {...params} />}
            componentsProps={{actionBar: {actions: []}}}
            showToolbar={false}
            disablePast
            renderDay={(day, selectedDays, pickersDayProps) => {
              let selectedMuiClass = ''

              if (dates.includes(dayjs(day).format('YYYY-MM-DD'))) {
                selectedMuiClass = 'Mui-selected'
              }

              return <PickersDay className={selectedMuiClass} {...pickersDayProps} />
            }}
            disableHighlightToday={true}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseSendProductionPlan()} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={handleConfirm} variant="contained" disabled={dates.length == 0}>
          Odoslať
        </Button>
      </DialogActions>
    </Dialog>
  )
}
