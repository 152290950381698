import React from 'react'
import {Kgj} from '../api/generated'
import {Checkbox, FormControlLabel, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material'

type NominationDialogKgjsTableProps = {
  kgjsForBps: undefined | Kgj[]
  isSelected: (id: number | undefined) => boolean
  handleClickCheckBox: (id: number | undefined, checked: boolean) => void
}

export const NominationDialogKgjsTable: React.FC<NominationDialogKgjsTableProps> = ({
  kgjsForBps,
  isSelected,
  handleClickCheckBox,
}) => {
  return (
    <>
      <Typography variant="caption">KGJ v prevádzke</Typography>

      <Table size="small" sx={{padding: '20px 0'}}>
        <TableBody>
          {kgjsForBps
            ?.sort((a, b) => {
              const nameA = a.name ?? ''
              const nameB = b.name ?? ''
              return nameA.localeCompare(nameB)
            })
            .map((kgj, kgjIndex) => {
              return (
                <TableRow key={kgj.id}>
                  <TableCell key={kgjsForBps?.at(kgjIndex)?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSelected(kgjsForBps?.at(kgjIndex)?.id)}
                          onChange={(_, checked) => {
                            handleClickCheckBox(kgjsForBps?.at(kgjIndex)?.id, checked)
                          }}
                          disabled={!kgjsForBps?.at(kgjIndex)?.in_service}
                        />
                      }
                      label={kgjsForBps?.at(kgjIndex)?.name}
                      labelPlacement="end"
                      key={kgjsForBps?.at(kgjIndex)?.id}
                    />
                    {!isSelected(kgjsForBps?.at(kgjIndex)?.id) && <Typography variant="caption">Nevyrába</Typography>}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </>
  )
}
