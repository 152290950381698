import {Moving} from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import {Box, Button, Container, Link, Stack, Tab, Tabs} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useBucketStore, useBucketStoreContext} from '../../utils/BucketStoreContext'
import {AddNominationDialog} from '../AddNominationDialog'
import {CopyDayDialog} from '../CopyDayDialog'
import {UseProfileDialog} from '../UseProfileDialog'
import {BpsSelector} from './BpsSelector'
import {DatePicker} from './DatePicker'
import {GraphView} from './GraphView'
import {GroupNominationsTable} from './GroupNominationsTable'
import {NominationsTable} from './NominationsTable'
import {ProfilesTable} from './ProfilesTable'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {BpsDetail} from './BPSDetail/BpsDetail'
import {useNavigate} from 'react-router'
import {absolutePath} from '../../navigation/utils'
import {isBpsEditable, isUserGroupManagerOrGroupOperator} from '../../utils/validateUserRolesAndBps'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {getProductionStatusEnum} from '../../api/nominationsApi'
import {OperationView} from './OperationView'

export const Dashboard: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<
    'graph-view' | 'table-view' | 'profiles-view' | 'group-view' | 'bps-detail-view' | 'operation-view'
  >('table-view')

  const {data: selectedDate, setData: setSelectedDate} = useBucketStore('selectedDate')
  const {setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')
  const {setData: setProductionStatusEnum} = useBucketStore('productionStatusEnum')

  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const {run: runGetProductionStatusEnum} = useAsyncMethodWithErrorHandling(getProductionStatusEnum)

  const [isAddNominationDialogOpen, setIsAddNominationDialogOpen] = useState<boolean>(false)

  const [isCopyDayDialogOpen, setIsCopyDayDialogOpen] = useState<boolean>(false)

  const [isUseProfileDialogOpen, setIsUseProfileDialogOpen] = useState<boolean>(false)

  const navigation = useNavigate()
  const {clearAllBuckets} = useBucketStoreContext()

  const handleCloseCopyDayDialog = () => {
    setIsCopyDayDialogOpen(false)
  }

  const handleCloseUseProfileDialogOpen = () => {
    setIsUseProfileDialogOpen(false)
  }

  const logout = () => {
    clearAllBuckets()
    sessionStorage.clear()
    navigation(absolutePath('login'))
  }

  const goToDispatch = () => {
    if (isUserGroupManagerOrGroupOperator(userInfo)) {
      navigation(absolutePath('dispatch'))
    }
  }

  const openNominationTableForBpsAndPeriod = () => {
    setSelectedTab('table-view')
    setBpsNominationTableOpen(true)
  }

  dayjs.extend(utc)
  dayjs.extend(timezone)

  const isDateInPast = dayjs(selectedDate).isBefore(dayjs(), 'day')
  const isFailureTime =
    dayjs(selectedDate).isSame(dayjs(), 'day') ||
    (dayjs(selectedDate).isSame(dayjs().add(1, 'day'), 'day') &&
      (dayjs().add(1, 'day').tz('Europe/Bratislava').hour() > 8 ||
        (dayjs().add(1, 'day').tz('Europe/Bratislava').hour() == 8 && dayjs().minute() >= 50)))

  useEffect(() => {
    if (selectedTab != 'table-view') {
      setBpsNominationTableOpen(false)
    }
  }, [selectedTab, setBpsNominationTableOpen])

  const fetchProductionStatusEnum = React.useCallback(async () => {
    const result = (await runGetProductionStatusEnum()).data
    setProductionStatusEnum(result)
  }, [runGetProductionStatusEnum, setProductionStatusEnum])

  useEffect(() => {
    fetchProductionStatusEnum()
  }, [fetchProductionStatusEnum])

  return (
    <>
      <Stack sx={{position: 'relative'}}>
        <Box sx={{position: 'absolute', right: 0, top: '-30px'}}>
          <Button
            variant="text"
            sx={{right: 15, display: isUserGroupManagerOrGroupOperator(userInfo) ? null : 'none'}}
            onClick={() => goToDispatch()}
          >
            Dispečing
          </Button>

          <Link component="button" variant="subtitle2" onClick={() => logout()}>
            Odhlásiť sa
          </Link>
        </Box>

        <Container maxWidth="xs" sx={{justifyContent: 'center', display: 'flex', marginBottom: '12px'}}>
          <BpsSelector />
        </Container>

        <Container maxWidth="xs" sx={{justifyContent: 'center', display: 'flex'}}>
          <DatePicker value={selectedDate} onChange={setSelectedDate} showToolbar={false} />
        </Container>

        <Stack direction="row" justifyContent="space-between" alignItems="start" marginTop={3} sx={{gap: '12px'}}>
          {(selectedTab == 'table-view' || selectedTab == 'graph-view') && (
            <Stack direction="row" flexWrap="wrap" sx={{gap: '12px', flex: 1}}>
              <Button
                endIcon={<Add fontSize="large" />}
                onClick={() => setIsAddNominationDialogOpen(true)}
                disabled={isDateInPast || !isBpsEditable(userInfo, selectedBpsId)}
                color={isFailureTime ? 'error' : 'primary'}
              >
                Zadať {isFailureTime ? 'poruchu' : 'nomináciu'}
              </Button>

              <Button
                endIcon={<Add fontSize="large" />}
                onClick={() => setIsCopyDayDialogOpen(true)}
                disabled={isDateInPast || !isBpsEditable(userInfo, selectedBpsId)}
              >
                Skopírovať deň
              </Button>

              <Button
                endIcon={<Moving fontSize="large" />}
                onClick={() => setIsUseProfileDialogOpen(true)}
                disabled={isDateInPast || !isBpsEditable(userInfo, selectedBpsId)}
              >
                Použiť profil
              </Button>
            </Stack>
          )}
        </Stack>

        <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: '8px'}}>
          <Tabs variant="scrollable" value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab label="Graf" value="graph-view" />
            <Tab label="Plán výroby" value="table-view" />
            <Tab label="Skupina" value="group-view" />
            <Tab label="Profily" value="profiles-view" />
            <Tab label="BPS Detail" value="bps-detail-view" />
            <Tab label="Prevádzka" value="operation-view" />
          </Tabs>
        </Box>
        {selectedTab === 'graph-view' && <GraphView />}
        {selectedTab === 'table-view' && <NominationsTable baseDate={selectedDate} />}
        {selectedTab === 'profiles-view' && <ProfilesTable />}
        {selectedTab === 'group-view' && (
          <GroupNominationsTable openNominationTableForBpsAndPeriod={openNominationTableForBpsAndPeriod} />
        )}
        {selectedTab === 'bps-detail-view' && <BpsDetail />}
        {selectedTab === 'operation-view' && <OperationView />}
      </Stack>

      {isAddNominationDialogOpen && (
        <AddNominationDialog
          open={isAddNominationDialogOpen}
          onClose={() => setIsAddNominationDialogOpen(false)}
          baseDate={selectedDate}
          isFailureTime={isFailureTime}
          selectedTab={selectedTab}
        />
      )}
      {isCopyDayDialogOpen && (
        <CopyDayDialog open={isCopyDayDialogOpen} onClose={handleCloseCopyDayDialog} selectedTab={selectedTab} />
      )}
      {isUseProfileDialogOpen && (
        <UseProfileDialog
          open={isUseProfileDialogOpen}
          onClose={handleCloseUseProfileDialogOpen}
          selectedTab={selectedTab}
        />
      )}
    </>
  )
}
