import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'

type NominationIsOutOfRegLimitDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const NominationIsOutOfRegLimitDialog: React.FC<NominationIsOutOfRegLimitDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Nová nominácia</DialogTitle>
      <DialogContent>
        <Typography color={'#ff0000'}>Upozornenie::</Typography>
        <Typography color={'#ff0000'}>
          Nominácia OOM BPS je mimo regulačného rozsahu, preto nie je možné poskytovať podporné služby pre zvolenú
          periódu.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={() => onConfirm()} variant="contained">
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
