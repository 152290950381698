import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'
import {validateNumericValue} from '../utils/common'
import {BpsParametersExtended, UpdateGasRequest} from '../../src/api/generated'

type EditGasDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: (request: UpdateGasRequest) => void
  bpsParamsExtended: BpsParametersExtended
}

export const EditGasDialog: React.FC<EditGasDialogProps> = ({open, onClose, onConfirm, bpsParamsExtended}) => {
  const [volGasTankM3Value, setVolGasTankM3Value] = useState<number | undefined>(bpsParamsExtended.vol_gas_tank_m_3)
  const [minGasOperatingVolume, setMinGasOperatingVolume] = useState<number | undefined>(
    bpsParamsExtended.min_gas_tank_vol_m3,
  )
  const [maxGasOperatingVolume, setMaxGasOperatingVolume] = useState<number | undefined>(
    bpsParamsExtended.max_gas_tank_vol_m3,
  )

  const minMaxGasValueValidation = (): boolean => {
    if (
      minGasOperatingVolume != undefined &&
      maxGasOperatingVolume != undefined &&
      maxGasOperatingVolume < minGasOperatingVolume
    ) {
      return false
    }
    return true
  }

  const gasTankValidation = (): string => {
    if (volGasTankM3Value == undefined) {
      return 'Hodnota nesmie byť prázdna'
    }
    if (minGasOperatingVolume != undefined && volGasTankM3Value < minGasOperatingVolume) {
      return 'Min. prevádzkový objem nemôže byť vyšší ako objem plynojemu'
    }
    if (maxGasOperatingVolume != undefined && volGasTankM3Value < maxGasOperatingVolume) {
      return 'Max. prevádzkový objem nemôže byť vyšší ako objem plynojemu'
    }
    return ''
  }

  const valuesAreValid = () => {
    return minMaxGasValueValidation() && gasTankValidation() == ''
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upraviť Plynojem</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>Objem plynojemu: [m3]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (m3)"
                    type="text"
                    value={volGasTankM3Value}
                    onChange={(e) => {
                      if (validateNumericValue(e.target.value)) {
                        setVolGasTankM3Value(Number(e.target.value))
                      } else {
                        setVolGasTankM3Value(0)
                      }
                    }}
                    error={gasTankValidation() != ''}
                    helperText={gasTankValidation()}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>Min. prevádzkový objem: [m3]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (m3)"
                    type="text"
                    value={minGasOperatingVolume}
                    onChange={(e) => {
                      if (e.target.value != '' && validateNumericValue(e.target.value)) {
                        setMinGasOperatingVolume(Number(e.target.value))
                      } else {
                        setMinGasOperatingVolume(undefined)
                      }
                    }}
                    error={!minMaxGasValueValidation()}
                    helperText={
                      minMaxGasValueValidation()
                        ? ''
                        : 'Min. prevádzkový objem nemôže byť vyšši ako max. prevádzkový objem'
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>Max. prevádzkový objem: [m3]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (m3)"
                    type="text"
                    value={maxGasOperatingVolume}
                    onChange={(e) => {
                      if (e.target.value != '' && validateNumericValue(e.target.value)) {
                        setMaxGasOperatingVolume(Number(e.target.value))
                      } else {
                        setMaxGasOperatingVolume(undefined)
                      }
                    }}
                    error={!minMaxGasValueValidation()}
                    helperText={
                      minMaxGasValueValidation()
                        ? ''
                        : 'Max. prevádzkový objem nemôže byť nižší ako min. prevádzkový objem'
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button
          onClick={() => {
            if (!valuesAreValid()) {
              return
            }

            const updateRequest: UpdateGasRequest = {
              vol_gas_tank_m_3: volGasTankM3Value,
              min_gas_tank_vol_m3: minGasOperatingVolume,
              max_gas_tank_vol_m3: maxGasOperatingVolume,
            }
            onConfirm(updateRequest)
          }}
          variant="contained"
          disabled={!valuesAreValid()}
        >
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
