import dayjs from 'dayjs'
import {TIME_INTERVAL_MS} from '../utils/constants'

export const formatTime = (timestamp: string | undefined) => {
  if (!timestamp) return ''
  return dayjs(timestamp).format('HH:mm')
}

export const formatTimeRange = (timeFrom: string | undefined, timeTo: string | undefined) => {
  return `${formatTime(timeFrom)} - ${formatTime(timeTo)}`
}

export const calculatePeriodFromIsoDate = (timeFrom: string) => {
  const dayStart = dayjs(timeFrom).startOf('day')
  const diff = dayjs(timeFrom).diff(dayStart)
  const period = Math.floor(diff / TIME_INTERVAL_MS)
  return period + 1
}

export const calculatePeriodFromTime = (time: string) => {
  const timeParts = time.split(':')
  const hours = Number(timeParts[0])
  const minutes = Number(timeParts[1])
  const sumMs = (hours * 60 + minutes) * 60 * 1000
  const period = Math.floor(sumMs / TIME_INTERVAL_MS)
  return period + 1
}

export const formatTimeToDate = (time: string) => {
  const splittedTime = time.split(':')
  return dayjs().set('hour', Number(splittedTime[0])).set('minute', Number(splittedTime[1])).startOf('minute')
}
