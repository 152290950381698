/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyProfileRequest } from '../model';
// @ts-ignore
import { ApplyProfileResponse } from '../model';
// @ts-ignore
import { CreateProfileRequest } from '../model';
// @ts-ignore
import { GetNominationProfilesResponse } from '../model';
// @ts-ignore
import { GetNominationProfilesWithKgjsResponse } from '../model';
// @ts-ignore
import { GetProfilesResponse } from '../model';
// @ts-ignore
import { Profile } from '../model';
// @ts-ignore
import { UpdateProfileRequest } from '../model';
// @ts-ignore
import { UpdateProfileWithKgjsRequest } from '../model';
/**
 * ProfileControllerApi - axios parameter creator
 * @export
 */
export const ProfileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplyProfileRequest} applyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyProfile: async (applyProfileRequest: ApplyProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyProfileRequest' is not null or undefined
            assertParamExists('applyProfile', 'applyProfileRequest', applyProfileRequest)
            const localVarPath = `/api/profile/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplyProfileRequest} applyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyProfileV2: async (applyProfileRequest: ApplyProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyProfileRequest' is not null or undefined
            assertParamExists('applyProfileV2', 'applyProfileRequest', applyProfileRequest)
            const localVarPath = `/api/v2/profile/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProfileRequest} createProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile: async (createProfileRequest: CreateProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProfileRequest' is not null or undefined
            assertParamExists('createProfile', 'createProfileRequest', createProfileRequest)
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProfileRequest} createProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileV2: async (createProfileRequest: CreateProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProfileRequest' is not null or undefined
            assertParamExists('createProfileV2', 'createProfileRequest', createProfileRequest)
            const localVarPath = `/api/v2/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (id: number, bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProfile', 'id', id)
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('deleteProfile', 'bpsId', bpsId)
            const localVarPath = `/api/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileV2: async (id: number, bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProfileV2', 'id', id)
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('deleteProfileV2', 'bpsId', bpsId)
            const localVarPath = `/api/v2/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileNominations: async (bpsId: number, profileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getProfileNominations', 'bpsId', bpsId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getProfileNominations', 'profileId', profileId)
            const localVarPath = `/api/profile/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile_id'] = profileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileNominationsV2: async (bpsId: number, profileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getProfileNominationsV2', 'bpsId', bpsId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getProfileNominationsV2', 'profileId', profileId)
            const localVarPath = `/api/v2/profile/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile_id'] = profileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles: async (bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getProfiles', 'bpsId', bpsId)
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProfileRequest} updateProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (updateProfileRequest: UpdateProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProfileRequest' is not null or undefined
            assertParamExists('updateProfile', 'updateProfileRequest', updateProfileRequest)
            const localVarPath = `/api/profile/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProfileWithKgjsRequest} updateProfileWithKgjsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileV2: async (updateProfileWithKgjsRequest: UpdateProfileWithKgjsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProfileWithKgjsRequest' is not null or undefined
            assertParamExists('updateProfileV2', 'updateProfileWithKgjsRequest', updateProfileWithKgjsRequest)
            const localVarPath = `/api/v2/profile/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileWithKgjsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileControllerApi - functional programming interface
 * @export
 */
export const ProfileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplyProfileRequest} applyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyProfile(applyProfileRequest: ApplyProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplyProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyProfile(applyProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplyProfileRequest} applyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyProfileV2(applyProfileRequest: ApplyProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplyProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyProfileV2(applyProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProfileRequest} createProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfile(createProfileRequest: CreateProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfile(createProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProfileRequest} createProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfileV2(createProfileRequest: CreateProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfileV2(createProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(id: number, bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(id, bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfileV2(id: number, bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfileV2(id, bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileNominations(bpsId: number, profileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationProfilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileNominations(bpsId, profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileNominationsV2(bpsId: number, profileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationProfilesWithKgjsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileNominationsV2(bpsId, profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfiles(bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfiles(bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateProfileRequest} updateProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(updateProfileRequest: UpdateProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationProfilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(updateProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateProfileWithKgjsRequest} updateProfileWithKgjsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfileV2(updateProfileWithKgjsRequest: UpdateProfileWithKgjsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationProfilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfileV2(updateProfileWithKgjsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileControllerApi - factory interface
 * @export
 */
export const ProfileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplyProfileRequest} applyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyProfile(applyProfileRequest: ApplyProfileRequest, options?: any): AxiosPromise<ApplyProfileResponse> {
            return localVarFp.applyProfile(applyProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplyProfileRequest} applyProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyProfileV2(applyProfileRequest: ApplyProfileRequest, options?: any): AxiosPromise<ApplyProfileResponse> {
            return localVarFp.applyProfileV2(applyProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProfileRequest} createProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile(createProfileRequest: CreateProfileRequest, options?: any): AxiosPromise<Profile> {
            return localVarFp.createProfile(createProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProfileRequest} createProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileV2(createProfileRequest: CreateProfileRequest, options?: any): AxiosPromise<Profile> {
            return localVarFp.createProfileV2(createProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(id: number, bpsId: number, options?: any): AxiosPromise<Profile> {
            return localVarFp.deleteProfile(id, bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfileV2(id: number, bpsId: number, options?: any): AxiosPromise<Profile> {
            return localVarFp.deleteProfileV2(id, bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileNominations(bpsId: number, profileId: number, options?: any): AxiosPromise<GetNominationProfilesResponse> {
            return localVarFp.getProfileNominations(bpsId, profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {number} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileNominationsV2(bpsId: number, profileId: number, options?: any): AxiosPromise<GetNominationProfilesWithKgjsResponse> {
            return localVarFp.getProfileNominationsV2(bpsId, profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles(bpsId: number, options?: any): AxiosPromise<GetProfilesResponse> {
            return localVarFp.getProfiles(bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProfileRequest} updateProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(updateProfileRequest: UpdateProfileRequest, options?: any): AxiosPromise<GetNominationProfilesResponse> {
            return localVarFp.updateProfile(updateProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProfileWithKgjsRequest} updateProfileWithKgjsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileV2(updateProfileWithKgjsRequest: UpdateProfileWithKgjsRequest, options?: any): AxiosPromise<GetNominationProfilesResponse> {
            return localVarFp.updateProfileV2(updateProfileWithKgjsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applyProfile operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiApplyProfileRequest
 */
export interface ProfileControllerApiApplyProfileRequest {
    /**
     * 
     * @type {ApplyProfileRequest}
     * @memberof ProfileControllerApiApplyProfile
     */
    readonly applyProfileRequest: ApplyProfileRequest
}

/**
 * Request parameters for applyProfileV2 operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiApplyProfileV2Request
 */
export interface ProfileControllerApiApplyProfileV2Request {
    /**
     * 
     * @type {ApplyProfileRequest}
     * @memberof ProfileControllerApiApplyProfileV2
     */
    readonly applyProfileRequest: ApplyProfileRequest
}

/**
 * Request parameters for createProfile operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiCreateProfileRequest
 */
export interface ProfileControllerApiCreateProfileRequest {
    /**
     * 
     * @type {CreateProfileRequest}
     * @memberof ProfileControllerApiCreateProfile
     */
    readonly createProfileRequest: CreateProfileRequest
}

/**
 * Request parameters for createProfileV2 operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiCreateProfileV2Request
 */
export interface ProfileControllerApiCreateProfileV2Request {
    /**
     * 
     * @type {CreateProfileRequest}
     * @memberof ProfileControllerApiCreateProfileV2
     */
    readonly createProfileRequest: CreateProfileRequest
}

/**
 * Request parameters for deleteProfile operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiDeleteProfileRequest
 */
export interface ProfileControllerApiDeleteProfileRequest {
    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiDeleteProfile
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiDeleteProfile
     */
    readonly bpsId: number
}

/**
 * Request parameters for deleteProfileV2 operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiDeleteProfileV2Request
 */
export interface ProfileControllerApiDeleteProfileV2Request {
    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiDeleteProfileV2
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiDeleteProfileV2
     */
    readonly bpsId: number
}

/**
 * Request parameters for getProfileNominations operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiGetProfileNominationsRequest
 */
export interface ProfileControllerApiGetProfileNominationsRequest {
    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiGetProfileNominations
     */
    readonly bpsId: number

    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiGetProfileNominations
     */
    readonly profileId: number
}

/**
 * Request parameters for getProfileNominationsV2 operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiGetProfileNominationsV2Request
 */
export interface ProfileControllerApiGetProfileNominationsV2Request {
    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiGetProfileNominationsV2
     */
    readonly bpsId: number

    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiGetProfileNominationsV2
     */
    readonly profileId: number
}

/**
 * Request parameters for getProfiles operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiGetProfilesRequest
 */
export interface ProfileControllerApiGetProfilesRequest {
    /**
     * 
     * @type {number}
     * @memberof ProfileControllerApiGetProfiles
     */
    readonly bpsId: number
}

/**
 * Request parameters for updateProfile operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiUpdateProfileRequest
 */
export interface ProfileControllerApiUpdateProfileRequest {
    /**
     * 
     * @type {UpdateProfileRequest}
     * @memberof ProfileControllerApiUpdateProfile
     */
    readonly updateProfileRequest: UpdateProfileRequest
}

/**
 * Request parameters for updateProfileV2 operation in ProfileControllerApi.
 * @export
 * @interface ProfileControllerApiUpdateProfileV2Request
 */
export interface ProfileControllerApiUpdateProfileV2Request {
    /**
     * 
     * @type {UpdateProfileWithKgjsRequest}
     * @memberof ProfileControllerApiUpdateProfileV2
     */
    readonly updateProfileWithKgjsRequest: UpdateProfileWithKgjsRequest
}

/**
 * ProfileControllerApi - object-oriented interface
 * @export
 * @class ProfileControllerApi
 * @extends {BaseAPI}
 */
export class ProfileControllerApi extends BaseAPI {
    /**
     * 
     * @param {ProfileControllerApiApplyProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public applyProfile(requestParameters: ProfileControllerApiApplyProfileRequest, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).applyProfile(requestParameters.applyProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiApplyProfileV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public applyProfileV2(requestParameters: ProfileControllerApiApplyProfileV2Request, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).applyProfileV2(requestParameters.applyProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiCreateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public createProfile(requestParameters: ProfileControllerApiCreateProfileRequest, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).createProfile(requestParameters.createProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiCreateProfileV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public createProfileV2(requestParameters: ProfileControllerApiCreateProfileV2Request, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).createProfileV2(requestParameters.createProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiDeleteProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public deleteProfile(requestParameters: ProfileControllerApiDeleteProfileRequest, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).deleteProfile(requestParameters.id, requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiDeleteProfileV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public deleteProfileV2(requestParameters: ProfileControllerApiDeleteProfileV2Request, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).deleteProfileV2(requestParameters.id, requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiGetProfileNominationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public getProfileNominations(requestParameters: ProfileControllerApiGetProfileNominationsRequest, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).getProfileNominations(requestParameters.bpsId, requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiGetProfileNominationsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public getProfileNominationsV2(requestParameters: ProfileControllerApiGetProfileNominationsV2Request, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).getProfileNominationsV2(requestParameters.bpsId, requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiGetProfilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public getProfiles(requestParameters: ProfileControllerApiGetProfilesRequest, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).getProfiles(requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiUpdateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public updateProfile(requestParameters: ProfileControllerApiUpdateProfileRequest, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).updateProfile(requestParameters.updateProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileControllerApiUpdateProfileV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public updateProfileV2(requestParameters: ProfileControllerApiUpdateProfileV2Request, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).updateProfileV2(requestParameters.updateProfileWithKgjsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
