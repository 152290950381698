import dayjs from 'dayjs'
import {TIME_INTERVAL_ALL_DAY_HOURS, TIME_INTERVAL_MS} from './constants'
import {TooltipItem} from 'chart.js'

export const timestampToPeriod = (timestamp: string, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  const diffMs = dayjs(timestamp).diff(dayStart)
  const periodsSinceStart = diffMs / TIME_INTERVAL_MS
  // human-friendly periods start at 1
  return periodsSinceStart + 1
}

export const timestampToHourPeriod = (timestamp: string, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  const diffMs = dayjs(timestamp).diff(dayStart)
  const periodsSinceStart = diffMs / TIME_INTERVAL_ALL_DAY_HOURS
  // human-friendly periods start at 1
  return periodsSinceStart + 1
}

export const periodToTimestamp = (period: number, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  // human-friendly periods start at 1
  const diffMs = (period - 1) * TIME_INTERVAL_MS

  const periodStart = dayStart.add(diffMs, 'ms')
  const periodEnd = periodStart.add(TIME_INTERVAL_MS, 'ms')

  return {periodStart: periodStart.toISOString(), periodEnd: periodEnd.toISOString()}
}

export const hourPeriodToTimestamp = (period: number, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  // human-friendly periods start at 1
  const diffMs = (period - 1) * TIME_INTERVAL_ALL_DAY_HOURS

  const periodStart = dayStart.add(diffMs, 'ms')
  const periodEnd = periodStart.add(TIME_INTERVAL_ALL_DAY_HOURS, 'ms')

  return {periodStart: periodStart.toISOString(), periodEnd: periodEnd.toISOString()}
}

export const validateNumericValueBelow100 = (value: string) => {
  const regex = /^([0-9\b]{1,2}|100)$/
  return regex.test(value)
}

export const validateNumericValueBelow100AndInRange = (value: string, minValue: number, maxValue: number) => {
  const regex = /^([0-9\b]{1,2}|100)$/
  return regex.test(value) && Number(value) >= minValue && Number(value) <= maxValue
}

export const validateNumericValue = (value: string) => {
  const regex = /^\d+$/
  if (value === '' || regex.test(value)) {
    return true
  }
}

export const parseToFixedNumber = (value: number, precision: number) => Number(value.toFixed(precision))

export const transformToKwWithEndKw = (valueToConvert: number | undefined) =>
  (valueToConvert !== undefined ? parseToFixedNumber(valueToConvert * 1000, 3) : '-') + ' kW'

export const transformToKwWithEndKwWithoutDecimals = (valueToConvert: number | undefined) =>
  (valueToConvert !== undefined ? parseToFixedNumber(valueToConvert * 1000, 0) : '-') + ' kW'

export const transformToKwNumber = (valueToConvert: number | undefined) =>
  valueToConvert !== undefined ? parseToFixedNumber(valueToConvert * 1000, 3) : undefined

export const transformToMwNumber = (valueToConvert: number | undefined) => (valueToConvert ? valueToConvert / 1000 : 0)

export const formatTooltipTitle = (ctx: TooltipItem<'line'>[]) => `${ctx[0].label} - ${ctx[0].dataIndex + 1}`
