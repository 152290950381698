import React from 'react'
import {transformToKwWithEndKw, transformToKwWithEndKwWithoutDecimals} from '../utils/common'
import {NominationExtendedBase} from '../types/nominationExtended'
import {TextField, Typography} from '@mui/material'
import {Kgj, NominationProfile} from '../api/generated'

type NominationDialogTechParamsProps = {
  tvsMw: number | undefined
  ovsMw: number | undefined
  editedNomination: NominationExtendedBase | NominationProfile
  bpsInstMw: number
  onEditProperties: (changes: Partial<NominationExtendedBase>) => void
  kgjsForBps: Kgj[]
  selectedKgjs: number[]
}

export const NominationDialogTechParams: React.FC<NominationDialogTechParamsProps> = ({
  tvsMw,
  ovsMw,
  editedNomination,
  onEditProperties,
  bpsInstMw,
  kgjsForBps,
  selectedKgjs,
}) => {
  return (
    <>
      <Typography variant="caption">
        Nominácia OOM BPS:{' '}
        {editedNomination.value_percentage &&
          tvsMw &&
          transformToKwWithEndKwWithoutDecimals(bpsInstMw * (editedNomination.value_percentage / 100) - tvsMw)}
      </Typography>

      <Typography variant="caption">
        Dosiahnuteľný rozsah KGJ:{' '}
        {transformToKwWithEndKw(
          kgjsForBps
            ?.filter((item) => selectedKgjs?.indexOf(item.id) !== -1)
            .reduce(function (prev, current) {
              return prev + current?.p_dos_min_mw
            }, 0),
        )}{' '}
        -{' '}
        {transformToKwWithEndKw(
          kgjsForBps
            ?.filter((item) => selectedKgjs?.indexOf(item.id) !== -1)
            .reduce(function (prev, current) {
              return prev + current?.p_dos_max_mw
            }, 0),
        )}
      </Typography>
      <Typography variant="caption">
        Regulačný rozsah KGJ:{' '}
        {transformToKwWithEndKw(
          kgjsForBps
            ?.filter((item) => selectedKgjs?.indexOf(item.id) !== -1)
            .reduce(function (prev, current) {
              return prev + current?.p_reg_min_mw
            }, 0),
        )}{' '}
        -{' '}
        {transformToKwWithEndKw(
          kgjsForBps
            ?.filter((item) => selectedKgjs?.indexOf(item.id) !== -1)
            .reduce(function (prev, current) {
              return prev + current?.p_reg_max_mw
            }, 0),
        )}
      </Typography>

      <Typography variant="caption">Tech. vlastná spotreba: {transformToKwWithEndKw(tvsMw)}</Typography>
      <Typography variant="caption">Ostatná vlastná spotreba: {transformToKwWithEndKw(ovsMw)}</Typography>
      <TextField
        margin="normal"
        label="Poznámka"
        multiline
        rows={4}
        value={editedNomination.note}
        onChange={(e) => onEditProperties({note: e.target.value})}
      />
    </>
  )
}
